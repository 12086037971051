import React from "react";
import Image from 'images/resources/top_image.jpg';

const TopImage = () => {
  return (
    <img src={Image} alt="top img"></img>
  );
}

export default TopImage;
