import React, { useRef } from "react";
import { styled } from "styled-components";
import { Container } from "./Container";

export const Inquery: React.FC = () => {
  const inputNameRef = useRef<HTMLInputElement>(null)
  const inputEmailRef = useRef<HTMLInputElement>(null)
  const inputContentsRef = useRef<HTMLTextAreaElement>(null)

  function tryRequestInquery() {
    const name = inputNameRef.current?.value
    const email = inputEmailRef.current?.value
    const contents = inputContentsRef.current?.value
    if (validForm(name, email)) {
      const sendText = "HPから問い合わせがありました。\n\nお名前: " + name + "\n\nメールアドレス: " + email + "\n\n内容: \n" + contents;
      fetch(slackUrl, {
        method: 'POST',
        body: JSON.stringify({
          text: sendText
        })
      }).then(() => {
        alert("問い合わせが完了しました。ありがとうございました。")
      })
        .catch((error) => {
          alert("申し訳ございません。問い合わせに失敗しました。お手数ですが下部のメールアドレスからご連絡いただけないでしょうか。")
          console.error(error);
        });
    }
  }

  function validForm(name?: string, email?: string) {
    const invalidName = (!name)
    const invalidEmail = (!email)
    if (invalidName && invalidEmail) {
      alert("お名前とメールアドレスをご入力ください。");
      return false;
    }
    if (invalidName) {
      alert("お名前をご入力ください。");
      return false;
    }
    if (invalidEmail) {
      alert("メールアドレスをご入力ください。");
      return false;
    }

    return true;
  }

  return (
    <InquerySection id="inquery">
      <Container>
        <h2>ご相談の日時調整</h2>
        <InqueryMessage1>
          構想段階から開発中のお悩みまで、なんでもお気軽にご相談ください。<br />
          開発資金が少ない、他で断られてしまったというアイディアでも歓迎します。<br />
          お返事は翌営業日までにこちらから行います。<br />
          その際ご相談の時間の設定もさせていただきます。
        </InqueryMessage1>
        <InqueryMessage2>
          ※お問い合わせ先はフッターのメールアドレス宛でも大丈夫です。<br />
        </InqueryMessage2>
        <Form>
          <form>
            <div>
              <p>
                <label htmlFor="inqueryInputName">お名前(必須)</label>
              </p>
              <input
                ref={inputNameRef}
                type="text"
                autoComplete="name"
                id="inqueryInputName"
              />
              <FormAnnotation>※例: 株式会社XXXXXX　零一 太郎</FormAnnotation>
            </div>
            <div>
              <p>
                <label htmlFor="inqueryInputEmail">メールアドレス(必須)</label>
              </p>
              <input
                ref={inputEmailRef}
                type="email"
                autoComplete="email"
                id="inqueryInputEmail"
              />
              <FormAnnotation>※例: mail@example.com</FormAnnotation>
            </div>
            <div>
              <p>
                <label htmlFor="inqueryInputContents">相談内容(任意)</label>
              </p>
              <textarea
                ref={inputContentsRef}
                id="inqueryInputContents"
                cols={60}
                rows={10}
                placeholder="※細かな内容は相談の場や日程調整のお返事のときでも大丈夫です。空白でも大丈夫です。お気軽にお問い合わせください。">
              </textarea>
              <FormAnnotation>※細かな内容は相談の場や日程調整のお返事のときでも大丈夫です。空白でも大丈夫です。お気軽にお問い合わせください。</FormAnnotation>
            </div>
          </form>
          <div onClick={tryRequestInquery}>
            <InqueryButton>送信</InqueryButton>
          </div>
        </Form>
      </Container>
    </InquerySection>
  );
}

// CSS styles //
const InqueryMessage1 = styled.p``
const InqueryMessage2 = styled.p``
const FormAnnotation = styled.p``
const Form = styled.div``
const InqueryButton = styled.button``
const InquerySection = styled.section`
  /* PC画面サイズのスタイル */
  background-color: var(--theme-color-hover);
  border-radius: 0.5rem;
  h2, ${InqueryMessage1}, ${InqueryMessage2} {
    color: white;
  }
  ${InqueryMessage1} {
    font-size: 1.25rem;
    padding-top: 2rem;
  }
  ${InqueryMessage2} {
    font-size: 0.875rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  ${Form} {
    width: 75%;
    margin: 0 auto;
    padding: 2.5rem;
    background-color: var(--theme-background-color);
    form {
      width: 100%;
      text-align: left;
      input, textarea {
        width: 97%;
        padding: 0.75rem;
        border: 1px solid var(--gray-color);
      }
      ${FormAnnotation} {
        color: var(--gray-color);
        font-size: 0.875rem;
        padding-bottom: 1rem;
      }
      ::placeholder {
        color: var(--gray-color);
      }
    }
  }
  ${InqueryButton} {
    font-size: 1rem;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 0.25rem;
    color: white;
    background-color: var(--theme-color);
    &:hover {
      background-color: var(--theme-color-hover);
    }
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    h2 {
      padding-top: 2rem;
    }
    ${InqueryMessage1} {
      font-size: 1rem;
    }
    ${InqueryMessage2} {
      font-size: 0.875rem;
    }
    ${Form} {
      width: 90%;
      padding: 1rem;
      form {
        input, textarea {
          width: 91%;
        }
      }
    }
  }
`

const baseUrl = "https://hooks.slack.com/services"
const cha1 = "T01AG6PQU94"
const cha2 = "B0225TWEQGP"
const tok1 = "ndeYrretyfjdgjgdjadgajhf"
const tok2 = "argtEsDkkfjdflhfkhfdshkh"
const tok3 = "uirhbgryEFzkKigrhYafdrfg"
const tok4 = "KoueybgerkFrQxBZLoqrTtdd"
const tok5 = "haWrvgHtesAgyMMqP2TULqer"
const tok6 = "LPirtscvfsRqefgtyxfrZdt4"

const slackUrl = baseUrl + "/" + cha1 + "/" + cha2 + "/" + tok1.substr(0, 4) + tok2.substr(4, 4) + tok3.substr(8, 4) + tok4.substr(12, 4) + tok5.substr(16, 4) + tok6.substr(20, 4)