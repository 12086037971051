import React from "react";
import Image from 'images/resources/service2.jpg';

const ServiceImage2 = () => {
  return (
    <img src={Image} alt="service img"></img>
  );
}

export default ServiceImage2;
