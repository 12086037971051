export const Company = () => {
  const name = () => {
    return "ゼロイチハッカー";
  }

  const fullName = () => {
    return "株式会社ゼロイチハッカー";
  }

  const zipCode = () => {
    return "〒810-0041";
  }

  const address = () => {
    return "福岡市中央区大名 2-6-11 Fukuoka Growth Next";
  }

  const buildDate = () => {
    return "2021年10月04日";
  }

  const capital = () => {
    return "300万円";
  }

  const representive = () => {
    return "代表取締役社長 石垣尚紀";
  }

  const ishigaki = () => {
    return "石垣尚紀";
  }

  return(
    {
      name,
      fullName,
      zipCode,
      address,
      buildDate,
      capital,
      representive,
      ishigaki,
    }
  )
}