import React from 'react';

import ServiceImage1 from 'images/ServiceImage1';
import ServiceImage2 from 'images/ServiceImage2';
import ServiceImage3 from 'images/ServiceImage3';
import { styled } from 'styled-components';
import { Container } from './Container';

export const Feature: React.FC = () => {
  return(
  <FeatureSection id="services">
    <Container>
      <h2>私たちの開発サービスの特徴</h2>
      <Goal>
        <FeatureContents>
          <FeatureSentences>
            <h3>ビジネスの成功に向かう開発</h3>
            <p>
              システムの完成ではなくビジネスの成功をゴールとします。
              システムの仕様だけを聞いて実装をするのではなく、経験豊富なエンジニアが、仕様の決め方をアドバイスします。
            </p>
          </FeatureSentences>
          <FeatureImage>
            <ServiceImage1 />
          </FeatureImage>
        </FeatureContents>
      </Goal>

      <Team>
        <FeatureContents>
          <FeatureSentences>
            <h3>少数精鋭チームによる開発</h3>
            <p>
              仕様・設計・実装までトータルで行えるエンジニアが、設計原則をふまえてチーム開発を行うため、多数チームで行なった場合に発生しうる認識齟齬による手戻りやバグが発生せず、品質の高いシステムを低コストで構築します。
            </p>
          </FeatureSentences>
          <FeatureImage>
            <ServiceImage2 />
          </FeatureImage>
        </FeatureContents>
      </Team>

      <Quality>
        <FeatureContents>
          <FeatureSentences>
            <h3>ビジネスの成長を意識した開発</h3>
            <p>
              保守性と拡張性に優れたシステム開発を行うため、開発速度が下がりにくく、システム開発がビジネスの成長を妨げません。
            </p>
          </FeatureSentences>
          <FeatureImage>
            <ServiceImage3 />
          </FeatureImage>
        </FeatureContents>
      </Quality>
    </Container>
  </FeatureSection>
  )
}

// CSS styles //
const Goal              = styled.div``
const Team              = styled.div``
const Quality           = styled.div``
const FeatureContents   = styled.div``
const FeatureSentences  = styled.div``
const FeatureImage      = styled.div``
const FeatureSection    = styled.section`
  /* PC画面サイズのスタイル */
  /* Goal,Team,Qualityはスタイルがほぼ同じなのでまとめてスタイリングしています。 */
  ${Goal}, ${Team}, ${Quality} {
    /* 画像に合わせて縦幅を600pxで統一。それに伴い上下の余白調整をしています。 */
    height: 600px;
    padding: 0;
    display: flex;
    justify-content: center;
    ${FeatureContents} {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      /* 各文章の共通スタイル */
      ${FeatureSentences} {
        width: 50%;
        max-width: 800px;
        padding-right: 7.5rem;
        h3 {
          white-space: nowrap;
          display: flex;
          justify-content: center;
        }
        p {
          margin-top: 2rem;
          font-size: 1.25rem;
          font-weight: 300;
        }
      }
      /* 各画像の共通スタイル */
      ${FeatureImage} {
        width: 50%;
      }
    }
  }
  /* Teamだけ左右逆の表示のため、上記＋個別にスタイリングしています。 */
  ${Team} {
    ${FeatureSentences} {
      /* 上記よりもこちらを優先させるため、!impotantをつけています。 */
      padding-right: 0 !important;
      padding-left: 7.5rem;
    }
    ${FeatureImage} {
      order: -999;
    }
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    /* Goal,Team,Qualityの3セクションはスタイルがほぼ同じなのでまとめてスタイリングしています。 */
    ${Goal}, ${Team}, ${Quality} {
      /* PC表示のheightだと上下の余白が不自然になってしまうため auto にしています。 */
      height: auto;
      ${FeatureContents} {
        flex-direction: column;
        text-align: center;
        /* 各文章の共通スタイル */
        ${FeatureSentences} {
          width: 100%;
          padding: 0rem;
          p {
            text-align: left;
            font-size: 1rem;
          }
        }
        /* 各画像の共通スタイル */
        ${FeatureImage} {
          width: 100%;
          order: 0;
        }
      }
    }
  }
`