import React from "react";
import Image from 'images/resources/logo_name.png';

const LogoName = () => {
  return (
    <div>
      <img src={Image} style={{ width: "auto", height: "1.5rem" }} alt="logoname"></img>
    </div>
  );
}

export default LogoName;
