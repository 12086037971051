import React from 'react';

import { DevelopLogo } from 'components/modules/DevelopLogo'
import { DevelopBook } from 'components/modules/DevelopBook'
import { styled } from 'styled-components';
import { Container } from './Container';

// Logo
import RubyLogo from 'images/resources/develop_logos/ruby.png'
import RailsLogo from 'images/resources/develop_logos/rails.svg'
import DockerLogo from 'images/resources/develop_logos/docker.png'
import RedisLogo from 'images/resources/develop_logos/redis.png'
import RSpecLogo from 'images/resources/develop_logos/rspec.png'
import GithubLogo from 'images/resources/develop_logos/github.png'
import ReactLogo from 'images/resources/develop_logos/react.png'
import PivotalLogo from 'images/resources/develop_logos/pivotal.png'
import GithubActionsLogo from 'images/resources/develop_logos/github_actions.png'
import MySQLLogo from 'images/resources/develop_logos/mysql.png'
import NginxLogo from 'images/resources/develop_logos/nginx.png'
import NodeLogo from 'images/resources/develop_logos/node.svg'
import GatsbyLogo from 'images/resources/develop_logos/gatsby.png'
import MiroLogo from 'images/resources/develop_logos/miro.png'
import HerokuLogo from 'images/resources/develop_logos/heroku.png'
import SlackLogo from 'images/resources/develop_logos/slack.png'
import TrelloLogo from 'images/resources/develop_logos/trello.png'
import GCPLogo from 'images/resources/develop_logos/gcp.png'
import AgileLogo from 'images/resources/develop_logos/agile.png'

// Book
import KigyounoKagakuBook from 'images/resources/develop_logos/kigyou_kagaku.jpg'
import RunnigLeanBook from 'images/resources/develop_logos/runnng_lean.jpg'
import ScrumBootCampBook from 'images/resources/develop_logos/scrum_boot_camp.jpg'
import AgileMitumoriBook from 'images/resources/develop_logos/agile_mitumori.jpg'
import AgileSamuraiBook from 'images/resources/develop_logos/agile_samurai.jpg'
import ZassouBook from 'images/resources/develop_logos/zassou.jpg'
import ReadableCodeBook from 'images/resources/develop_logos/readable_code.jpg'
import ObjectGuideBook from 'images/resources/develop_logos/object_guide.jpg'

export const DevelopMap: React.FC = () => {
  return(
    <DevelopmapSection id="develop">
      <Container>
        <h2>開発技術</h2>
          {buildLogoJsxTags()}
          {buildBookJsxTags()}
      </Container>
    </DevelopmapSection>
  )
}

// ロゴを表示する一覧のjsxの生成
const buildLogoJsxTags = () => {
  let tags: React.ReactNode[] = []
  logos.forEach(function(logoParams, index) {
    tags.push(
      <StyledDevelopLogo key={index}>
        <DevelopLogo src={logoParams.src} link={logoParams.link} customStyle={ logoParams.style } />
      </StyledDevelopLogo>
    )
  })

  return (
    <DevelopmapItems>
      {tags}
    </DevelopmapItems>
  )
}

// 本を表示する一覧のjsxの生成
const buildBookJsxTags = () => {
  let tags: React.ReactNode[] = []
  books.forEach(function(bookParams, index) {
    tags.push(
      <StyledDevelopBook key={index}>
        <DevelopBook src={bookParams.src} link={bookParams.link} customStyle={{}} />
      </StyledDevelopBook>
    )
  })

  return (
    <DevelopmapItems>
      {tags}
    </DevelopmapItems>
  )
}

// CSS styles //
const StyledDevelopLogo = styled.div``
const StyledDevelopBook = styled.div``
const DevelopmapItems   = styled.div``
const DevelopmapSection = styled.section`
  /* PC画面サイズのスタイル */
  ${DevelopmapItems} {
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    grid-gap: 1.25rem;
    justify-content: center;
    justify-items: center;
    padding-top: 1.25rem;
    /* 左右の余白を持たせるためと、左右均等に見えるようにrightをすこし少なくしています。 */
    padding-left: 1rem;
    padding-right: 0.75rem;
    ${StyledDevelopLogo}, ${StyledDevelopBook} {
      padding-bottom: 5rem;
    }
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    ${DevelopmapItems} {
      grid-template-columns: repeat(2,minmax(0,1fr));
      ${StyledDevelopLogo}, ${StyledDevelopBook} {
        padding-bottom: 0;
      }
    }
  }
`

// 表示したいロゴ情報
// 本コミット時点では policy_link は使われていないが、わざわざ調べた情報なので念のため残しておく
const logos = [
  { src: RubyLogo, policy_link: "https://www.ruby-lang.org/ja/about/logo/", link: "https://www.ruby-lang.org/ja/about/", style: { width: "300px", height: "100px" } },
  { src: RailsLogo, policy_link: "https://rubyonrails.org/trademarks/" , link: "https://railsguides.jp/", style: {}},
  { src: AgileLogo, policy_link: "", link: "https://agilemanifesto.org/iso/ja/manifesto.html", style: {}},
  { src: DockerLogo, policy_link: "https://www.docker.com/legal/trademark-guidelines", link: "https://www.docker.com/company", style: { width: "180px" } },
  { src: "https://d0.awsstatic.com/logos/powered-by-aws.png", policy_link: "https://aws.amazon.com/what-is-cloud-computing", link: "https://aws.amazon.com/jp/what-is-aws/", style: {} },
  { src: GCPLogo, policy_link: "https://cloud.google.com/press?hl=ja", link: "https://cloud.google.com/docs", style: {}},
  { src: HerokuLogo, policy_link: "https://www.heroku.com/policy/trademark", link: "https://www.heroku.com/about", style: {}},
  { src: GithubLogo, policy_link: "https://github.com/logos", link: "https://github.com/about", style: {  width: "200px", height: "100px" }},
  { src: GithubActionsLogo, policy_link: "https://github.com/actions", link: "https://github.com/actions", style: {}},
  { src: SlackLogo, policy_link: "https://slack.com/intl/ja-jp/media-kit", link: "https://slack.com/intl/ja-jp/about", style: {}},
  { src: TrelloLogo, policy_link: "https://trello.com/ja/about", link: "https://trello.com/ja", style: {}},
  { src: PivotalLogo, policy_link: "https://www.pivotaltracker.com/branding-guidelines", link: "https://www.pivotaltracker.com/", style: {}},
  { src: MiroLogo, policy_link: "https://miro.com/index/", link: "https://miro.com/about/", style: {}},
  { src: ReactLogo, policy_link: "https://github.com/reactjs/reactjs.org/blob/master/LICENSE-DOCS.md", link: "https://reactjs.org/", style: { width: "200px", height: "100px"}},
  { src: RSpecLogo, policy_link: "https://github.com/rspec/rspec", link: "https://github.com/rspec/rspec", style: {  width: "250px", height: "100px" } },
  { src: MySQLLogo, policy_link: "https://www.mysql.com/about/legal/logos.html", link: "https://www.mysql.com/jp/about/", style: {}},
  { src: NginxLogo, policy_link: "https://www.f5.com/pdf/f5/f5-nginx-creative-guidelines.pdf", link: "https://www.nginx.com/about/", style: {  width: "200px", height: "80px" }},
  { src: NodeLogo, policy_link: "https://nodejs.org/en/about/trademark/", link: "https://nodejs.org/en/about/", style: {}},
  { src: GatsbyLogo, policy_link: "https://www.gatsbyjs.com/guidelines/logo", link: "https://www.gatsbyjs.com/about/", style: {}},
  { src: RedisLogo, policy_link: "https://redis.io/topics/trademark", link: "https://redis.io/", style: {}},
]

// 表示したい本情報
const books = [
  { src: KigyounoKagakuBook, link: "https://www.amazon.co.jp/%E5%85%A5%E9%96%80-%E8%B5%B7%E6%A5%AD%E3%81%AE%E7%A7%91%E5%AD%A6-%E7%94%B0%E6%89%80-%E9%9B%85%E4%B9%8B/dp/4296100947/" },
  { src: RunnigLeanBook, link: "https://www.amazon.co.jp/Running-Lean-%E2%80%95%E5%AE%9F%E8%B7%B5%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%A2%E3%83%83%E3%83%97-LEAN-%E3%82%A2%E3%83%83%E3%82%B7%E3%83%A5%E3%83%BB%E3%83%9E%E3%82%A6%E3%83%AA%E3%83%A3/dp/4873115914" },
  { src: ScrumBootCampBook, link: "https://www.amazon.co.jp/SCRUM-BOOT-CAMP-BOOK-%E8%A5%BF%E6%9D%91/dp/4798129712" },
  { src: AgileMitumoriBook, link: "https://www.amazon.co.jp/%E3%82%A2%E3%82%B8%E3%83%A3%E3%82%A4%E3%83%AB%E3%81%AA%E8%A6%8B%E7%A9%8D%E3%82%8A%E3%81%A8%E8%A8%88%E7%94%BB%E3%81%A5%E3%81%8F%E3%82%8A-%E4%BE%A1%E5%80%A4%E3%81%82%E3%82%8B%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%82%92%E8%82%B2%E3%81%A6%E3%82%8B%E6%A6%82%E5%BF%B5%E3%81%A8%E6%8A%80%E6%B3%95-Mike-Cohn/dp/4839924023" },
  { src: AgileSamuraiBook, link: "https://www.amazon.co.jp/%E3%82%A2%E3%82%B8%E3%83%A3%E3%82%A4%E3%83%AB%E3%82%B5%E3%83%A0%E3%83%A9%E3%82%A4%E2%88%92%E9%81%94%E4%BA%BA%E9%96%8B%E7%99%BA%E8%80%85%E3%81%B8%E3%81%AE%E9%81%93%E2%88%92-Jonathan-Rasmusson/dp/4274068560" },
  { src: ZassouBook, link: "https://www.amazon.co.jp/%E3%82%B6%E3%83%83%E3%82%BD%E3%82%A6-%E7%B5%90%E6%9E%9C%E3%82%92%E5%87%BA%E3%81%99%E3%83%81%E3%83%BC%E3%83%A0%E3%81%AE%E7%BF%92%E6%85%A3-%E5%80%89%E8%B2%AB%E7%BE%A9%E4%BA%BA-ebook/dp/B07WWK5KQK/ref=sr_1_1?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E9%9B%91%E8%8D%89+%E5%80%89%E8%B2%AB&qid=1624179035&s=books&sr=1-1"},
  { src: ReadableCodeBook, link: "https://www.amazon.co.jp/%E3%83%AA%E3%83%BC%E3%83%80%E3%83%96%E3%83%AB%E3%82%B3%E3%83%BC%E3%83%89-%E2%80%95%E3%82%88%E3%82%8A%E8%89%AF%E3%81%84%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E6%9B%B8%E3%81%8F%E3%81%9F%E3%82%81%E3%81%AE%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%81%A7%E5%AE%9F%E8%B7%B5%E7%9A%84%E3%81%AA%E3%83%86%E3%82%AF%E3%83%8B%E3%83%83%E3%82%AF-Theory-practice-Boswell/dp/4873115655/ref=pd_bxgy_img_2/357-0284726-0332773?_encoding=UTF8&pd_rd_i=4873115655&pd_rd_r=cca55ec7-3a62-4b76-bd7b-96e4a30b3594&pd_rd_w=oYgvW&pd_rd_wg=3xoUB&pf_rd_p=105d6769-bacf-43d4-85ea-a25cec858a3c&pf_rd_r=G16XVVA8Y2QGPDDAXDXD&psc=1&refRID=G16XVVA8Y2QGPDDAXDXD"},
  { src: ObjectGuideBook, link: "https://www.amazon.co.jp/%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E6%8C%87%E5%90%91%E8%A8%AD%E8%A8%88%E5%AE%9F%E8%B7%B5%E3%82%AC%E3%82%A4%E3%83%89-Ruby%E3%81%A7%E3%82%8F%E3%81%8B%E3%82%8B-%E9%80%B2%E5%8C%96%E3%81%97%E3%81%A4%E3%81%A5%E3%81%91%E3%82%8B%E6%9F%94%E8%BB%9F%E3%81%AA%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E8%82%B2%E3%81%A6%E6%96%B9-Sandi-Metz/dp/477418361X"},
]