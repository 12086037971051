import React from 'react';
import { Card } from 'components/modules/Card';
import { styled } from 'styled-components';
import { Container } from './Container';

export const DevelopMethod: React.FC = () => {
  return(
    <MethodSection id="method">
      <Container>
        <h2>開発方法</h2>
        <Cards>
          <StyledCard>
            <h3>仮説検証開発</h3>
            <p>
              ビジネスの仮説検証にリーン開発で応えます。
              開発体制としてスクラム・XP・かんばんをコーディネートしてアジャイルなシステムの開発を、
              複数のツール(かんばん・Wiki・ホワイトボード・タスク管理・チャット・ビデオ会議)を利用しながら、
              現在のビジネスのフェイズに応じて柔軟に進めていきます。
            </p>
          </StyledCard>
          <StyledCard>
            <h3>チームビルド</h3>
            <p>
              エンドユーザも含めた関係性作りのため対話とチームビルドを重視しています。
              リーンキャンバス、ドラッガー風エクササイズ、エレベーターピッチ、
              チーム成熟度などを取り入れた日々のコミュニケーションで、対話がビジネス的価値をうみだす、心理的安全性が高い環境を作ります。
            </p>
          </StyledCard>
          <StyledCard>
            <h3>初めからエンジニアの方向性が揃っているチーム体制</h3>
            <p>
              最初から高い規律のある状態・方向性が整った状態・心理的安全性が高い状態でのシステム開発体制を提供します。
              個別のフリーランスとの契約とは異なり、複数人のチーム体制のため、相互レビューでより高い品質のシステム開発を提供できます。
            </p>
          </StyledCard>
        </Cards>
      </Container>
    </MethodSection>
  )
}

// CSS styles //
const Cards = styled.div``
const StyledCard = styled(Card)``
const MethodSection = styled.section`
  /* PC画面サイズのスタイル */
  background-color: var(--theme-background-color);
  ${Cards} {
    display: flex;
    justify-content: center;
    ${StyledCard} {
      margin: 3rem 0.75rem;
      h3 {
        font-size: 1.25rem;
      }
      p {
        text-align: left;
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    ${Cards} {
      flex-direction: column;
      align-items: center;
      ${StyledCard} {
        max-width: 75%;
        margin: 1.5rem 0.75rem;
      }
    }
  }
`