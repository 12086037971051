import React from 'react';

const defaultStyle: React.CSSProperties = {
  width: "250px",
  maxWidth: "100%",
  height: "100px",
  objectFit: "contain",
  marginLeft: "auto",
  marginRight: "auto",
}

type Props = {
  src: string;
  className?: string;
  customStyle?: React.CSSProperties | null;
  link?: string;
}
export const DevelopLogo: React.FC<Props> = ({src, className = '', customStyle = null, link = "#"}) => {
  var style = defaultStyle
  if (customStyle) {
    style = { ...style, ...customStyle }
  }

  if(link === "#") {
    return(
      // <a>のみや<a href="">,<a href="#">だと警告が出るので<button>にすることで回避
      <button>
        <img className={className} src={src} style={style} alt="development logo"></img>
      </button>
    )
  } else {
    return(
      <a href={link} target="_blank" rel="noreferrer">
        <img className={className} src={src} style={style} alt="development logo"></img>
      </a>
    )
  }
}