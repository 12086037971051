import React from 'react';
import ReactDOM from 'react-dom/client';
import { View } from 'components/views/top/index'

// @note 注意
//  metaタグなどのhead要素は、public/index.htmlに直接記述しています。
const Page: React.FC = () => {
  return <View />;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Page />);