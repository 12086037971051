import React from 'react';
import { Company } from 'models/Company';
import { CopyEmailImage } from 'components/modules/Email';
import { styled } from 'styled-components';

export const Footer: React.FC = () => {
  const company = Company()
  return (
    <StyledFooter>
      <FooterContainer>
        <div>
          <h2>About Us</h2>
          <table>
            <tbody>
              <tr>
                <th>
                  <p>会社名</p>
                </th>
                <td>{company.fullName()}</td>
              </tr>
              <tr>
                <th>
                  <p>本店</p>
                </th>
                <td>
                  <p>{Company().zipCode()}</p>
                  <p>{Company().address()}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>設立年月日</p>
                </th>
                <td>{company.buildDate()}</td>
              </tr>
              <tr>
                <th>
                  <p>資本金</p>
                </th>
                <td>{company.capital()}</td>
              </tr>
              <tr>
                <th>
                  <p>代表者</p>
                </th>
                <td>{company.representive()}</td>
              </tr>
              <tr>
                <th rowSpan={2}>
                  <p>事業内容</p>
                </th>
                <td>
                  <p style={{ fontWeight: "700" }}>スポットCTOサービス事業</p>
                  <p>アイデア検証・ユーザインタビュー・プロトタイプ作成・開発ロードマップ作成・技術的相談など仮説検証全般の支援業務</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style={{ fontWeight: "700" }}>ゼロイチ開発サービス事業</p>
                  <p>PMFを目指したアジャイル型のシステム開発業務</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>連絡先</p>
                </th>
                <td>
                  <CopyEmailImage />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </FooterContainer>
      <Copyright>
        <small><a href="http://www.freepik.com" target="_blank" rel="noreferrer">Photos Designed by pikisuperstar / Freepik</a></small><br />
        <small>&copy; 2021- {company.fullName()} </small>
      </Copyright>
    </StyledFooter>
  )
}

// CSS styles //
const FooterContainer = styled.div``
const FooterButton    = styled.button``
const Copyright       = styled.div``
const StyledFooter    = styled.footer`
  /* PC画面サイズのスタイル */
  padding-top: 4rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  ${FooterContainer} {
    display: flex;
    justify-content: center;
    h2 {
      font-size: 1.125rem;
      text-align: left;
      padding: 0;
    }
    table {
      display: table;
      border: solid 2px;
      border-collapse: collapse;
      border-color: black;
      th, td {
        border: solid 1px;
        padding: 0.5rem 1rem;
      }
      p {
        margin: 0;
      }
    }
  }
  ${FooterButton} {
    font-size: 0.85rem;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 0.25rem;
    color: white;
    background-color: var(--theme-color);
    &:hover {
      background-color: var(--theme-color-hover);
    }
  }
  ${Copyright} {
    text-align: center;
    padding-top: 1rem;
    a {
      color: var(--theme-color);
      text-decoration: inherit;
      &:hover {
        color: var(--theme-color-hover);
      }
    }
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    padding-top: 2rem;
    ${FooterContainer} {
      padding-left: 0;
      table {
        /* 縦書きに設定。横幅が狭くなるときの改行を防ぐためです。 */
        th {
          text-align: center;
          writing-mode: vertical-rl;
        }
      }
    }
    ${Copyright} {
      text-align: center;
      padding-top: 1rem;
      padding-right: 0;
    }
  }
`