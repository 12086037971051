import React from 'react';
import 'css/index.css';

import { Layout } from 'components/layout/Layout';
import { Inquery } from 'components/molecules/Inquery';
import { Feature } from 'components/molecules/Feature';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import TopImage from 'images/TopImage';
import { MemberInformation } from 'components/molecules/MemberInfomation';
import { DevelopMethod } from 'components/molecules/DevelopMethod';
import { DevelopMap } from 'components/molecules/DevelopMap';
import { styled } from 'styled-components';
import { Container } from 'components/molecules/Container';

export const View: React.FC = () => {
  return (
    <Layout>
      <TopSection>
        <Container>
          <TopHeading>
            <h1>
              ビジネス視点と技術力<br className="br-disp-sp" />を合わせ持ったエンジニアが、<br />
              <span>ビジネスの構想からリリースまで</span><br className="br-disp-sp" />をサポートします。
            </h1>
          </TopHeading>
          <TopContents>
            <TopSentences>
              <TopSentence1>
                私たちは、<br />
                「システムの完成」を目指すのではなく、<br />
                「ビジネスの成功」を支援する<br />
                システム開発の専門家です。
              </TopSentence1>
              <TopSentence2>
                構想段階から開発中のお悩みまで、<br className="br-disp-sp" />なんでもお気軽にご相談ください。<br />
                開発資金が少ない、他で断られてしまった<br className="br-disp-sp" />というアイディアでも歓迎します。
              </TopSentence2>
              <IndexButton>
                <AnchorLink href="#inquery">
                  <button>相談する</button>
                </AnchorLink>
              </IndexButton>
            </TopSentences>
            <TopImg>
              <TopImage />
            </TopImg>
          </TopContents>
        </Container>
      </TopSection>
      <MemberInformation />
      <Feature />
      <DevelopMethod />
      <DevelopMap />
      <Inquery />
    </Layout>
  )
}

// CSS styles //
const TopHeading    = styled.div``
const TopContents   = styled.div``
const TopSentences  = styled.div``
const TopSentence1  = styled.p``
const TopSentence2  = styled.p``
const TopImg        = styled.div``
const IndexButton   = styled.div``
const TopSection    = styled.section`
  /* PC画面サイズのスタイル */
  ${TopHeading} {
    padding-bottom: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    h1 {
      font-size: 3rem;
      font-weight: 700;
      line-height: 1;
      /* 改行させないことで表示崩れを防いでいます */
      white-space: nowrap;
      span {
        color: var(--theme-color-hover);
      }
    }
  }
  ${TopContents} {
    display: flex;
    ${TopSentences} {
      width: 50%;
      text-align: left;
      /* 画面幅が狭くなったときに文字が隠れないように画像よりも前面に置く */
      z-index: calc(var(--header-zindex) - 50);
      ${TopSentence1} {
        font-size: 1.5rem;
        white-space: nowrap;
        padding-top: 1.5rem;
        padding-left: 4rem;
      }
      ${TopSentence2} {
        display: flex;
        justify-content: center;
        white-space: nowrap;
        padding-top: 3rem;
        padding-bottom: 0.5rem;
      }
    }
    ${TopImg} {
      width: 50%;
      /* 画像の縁をぼやかすCSS */
      position: relative;
      &::after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: inset 0 0 2rem 2rem white;
      }
    }
  }
  ${IndexButton} {
    padding-top: 2rem;
    text-align: center;
    button {
      font-size: 1rem;
      padding: 0.75rem 2rem;
      border: none;
      border-radius: 0.25rem;
      color: white;
      background-color: var(--theme-color);
      &:hover {
        background-color: var(--theme-color-hover);
      }
    }
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    ${TopHeading} {
      padding-bottom: 3rem;
      h1 {
        font-size: 1.5rem;
      }
    }
    ${TopContents} {
      flex-direction: column;
      ${TopSentences} {
        width: 100%;
        text-align: center;
        ${TopSentence1} {
          font-size: 1rem;
          padding-top: 0;
          padding-left: 0;
        }
        ${TopSentence2} {
          padding-top: 3rem;
          padding-bottom: 0.5rem;
        }
      }
      ${TopImg} {
        width: 100%;
        &::after {
          box-shadow: inset 0 0 1rem 1rem white;
        }
      }
    }
    ${IndexButton} {
      padding-top: 1rem;
      padding-left: 0rem;
      padding-bottom: 1rem;
      text-align: center;
    }
  }
`