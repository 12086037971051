import React, { useState } from "react";
import IshigakiImage1 from "images/resources/ishigaki.jpg";
import IshigakiImage2 from "images/resources/ishigaki2.jpg";
import IshigakiImage3 from "images/resources/ishigaki_back.jpg";
import MizunoImage1 from "images/resources/mizuno.jpg";
import MizunoImage2 from "images/resources/mizuno2.jpg";
import MizunoImage3 from "images/resources/mizuno_back.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faDev } from '@fortawesome/free-brands-svg-icons'
import Modal from "react-modal";
import { Ishigaki } from 'models/members/Ishigaki';
import { Mizuno } from 'models/members/Mizuno';
import { styled } from "styled-components";
import { Container } from "./Container";

// config //
const ishigaki = Ishigaki()
const mizuno = Mizuno()
const ishigakiProfiles = ishigaki.profiles()
const mizunoProfiles = mizuno.profiles()

// モーダルを閉じたときにスクロールを有効に戻す
const ableScroll = () => {
  document.body.style.overflow = 'auto'
};

// モーダルを開いた時にスクロールを無効にする
const  disableScroll = () => {
  document.body.style.overflow = 'hidden'
};

// react-modalの初期設定
Modal.setAppElement("#react-modal");
//Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.20)";

const modalCloseNo = 0;

export const MemberInformation: React.FC = () => {
  //モーダルの表示状態と切り替える為にState(props)を準備
  const [modalOpenNo, setModalOpenNo] = useState(modalCloseNo);
  // 表示する画像
  const [ishigakiImage, setIshigakiImage] = useState(IshigakiImage1);
  const [MizunoImage, setMizunoImage] = useState(MizunoImage1);

  // shouldCloseOnEscやshouldCloseOnOverlayCloseを使う場合に設定が必要
  const handleCloseModal = () => {
    setModalOpenNo(modalCloseNo)
  }

  return (
    <MemberSection id="member">
      <Container>
        <h2>開発メンバ紹介</h2>
        <Members>
          <MemberIshigaki>
            <h3>【{ ishigaki.name() }】</h3>
            <div onClick={() => setModalOpenNo(1)}>
              <img
                src={ishigakiImage}
                style={ trimImage }
                onMouseEnter={() => setIshigakiImage(IshigakiImage2)}
                onMouseLeave={() => setIshigakiImage(IshigakiImage1)}
                alt="ishigaki img"
              />
              <p>※クリックで詳細を表示します</p>
            </div>
            <a href={ishigakiProfiles.facebook} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebook} style={facebookIconStyle} />
            </a>
            <a href={ishigakiProfiles.devto} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faDev} style={devIconStyle} />
            </a>
            <a href={ishigakiProfiles.github} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faGithub} style={githubIconStyle} />
            </a>
            <div>
              { ishigaki.oneMessage() }
            </div>
          </MemberIshigaki>
          <MemberMizuno>
            <h3>【{ mizuno.name() }】</h3>
            <div onClick={() => setModalOpenNo(2)}>
              <img
                src={MizunoImage}
                style={ trimImage }
                onMouseEnter={() => setMizunoImage(MizunoImage2)}
                onMouseLeave={() => setMizunoImage(MizunoImage1)}
                alt="mizuno img"
              />
              <p>※クリックで詳細を表示します</p>
            </div>
            <a href={mizunoProfiles.github} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faGithub} style={githubIconStyle} />
            </a>
            <a href={mizunoProfiles.twitter} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faTwitter} style={twitterIconStyle} />
            </a>
            <a href={mizunoProfiles.qiita} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faSearch} style={qiitaIconStyle}/>
            </a>
            <div>
              { mizuno.oneMessage() }
            </div>
          </MemberMizuno>
        </Members>
      </Container>
      <Modal
        isOpen={modalOpenNo === 1}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        style={customModalStyles}
        onAfterOpen={disableScroll}
        onAfterClose={ableScroll}
        >
        <StyledModal style={ modalIshigakiStyles }>
          <h2>
            【<ruby> { ishigaki.name() } <rt>いしがき なおき</rt> </ruby>】
          </h2>
          <h3>{ ishigaki.oneMessage() }</h3>
          { ishigaki.information() }
          <ModalButton>
            <button onClick={() => handleCloseModal()} >閉じる</button>
          </ModalButton>
        </StyledModal>
      </Modal>

      <Modal
        isOpen={modalOpenNo === 2}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        style={customModalStyles}
        onAfterOpen={disableScroll}
        onAfterClose={ableScroll}
      >
        <StyledModal style={ modalMizunoStyles }>
          <h2>
            【<ruby> { mizuno.name() } <rt>みずの けんじ</rt> </ruby>】
          </h2>
          <h3>{ mizuno.oneMessage() }</h3>
          { mizuno.information() }
          <ModalButton>
            <button onClick={() => handleCloseModal()}>閉じる</button>
          </ModalButton>
        </StyledModal>
      </Modal>
      {/*  マウスホバー後の画像を先に読み込んでおくためhiddenでimg画像を設定する */}
      <HiddenImage src={IshigakiImage2} alt="hidden ishigaki img"/>
      <HiddenImage src={MizunoImage2} alt="hidden mizuno img"/>
    </MemberSection>
  );
}

// css styles //
const Members         = styled.div``
const MemberIshigaki  = styled.div``
const MemberMizuno    = styled.div``
const HiddenImage     = styled.img``
const MemberSection   = styled.section`
  /* PC画面サイズのスタイル */
  background-color: var(--theme-background-color);
  ${Members} {
    display: flex;
    ${MemberIshigaki}, ${MemberMizuno} {
      width: 100%;
      padding: 0 0.75rem;
      a {
        padding: 0 0.25rem;
      }
      p {
        color: #718096;
        font-size: 0.75rem;
        margin: 0rem;
      }
    }
  }
  ${HiddenImage} {
    display: none;
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    ${Members} {
      flex-direction: column;
      h3 {
        padding-top: 1rem;
      }
      ${MemberIshigaki}, ${MemberMizuno} {
        padding: 0;
      }
    }
  }
`

// モーダルのスタイル //
// モーダルはroot外のため別コンポーネントでスタイルを記述
const ModalButton = styled.div``
const StyledModal = styled.div`
  h2 {
    font-size: 2.5rem;
    padding: 0;
  }
  h4 {
    font-weight: 600;
    padding: 0.75rem 0;
  }
  ${ModalButton} {
    text-align: center;
    padding-top: 0.5rem;
    button {
      background-color: white;
      padding: 0.5rem 1.5rem;
      border-width: 0;
      border-radius: 0.25rem;
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
      &:hover {
        background-color: #f7fafc;
      }
    }
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    h2 {
      font-size: 1.5rem;
      text-align: center;
    }
    h4 {
      font-size: 1rem;
      text-align: center;
    }
    p {
      font-size: 0.875rem;
    }
  }
`

const customModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : '25%',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '70rem',
    maxWidth              : 'calc(100% - 4rem)',
    maxHeight             : 'calc(100% - 4rem)',
    boxShadow             : '0 0 1rem rgba(0,0,0,.30)',
  },
  overlay : {
    // ヘッダーよりも前面に表示させる
    zIndex                : 'calc( var(--header-zindex) + 100 )'
  }
};

const commonModalStyles = {
  backgroundSize: "contain",
  backgroundColor: "rgba(255,255,255,0.85)",
  backgroundBlendMode: "lighten",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  padding: "0 0.75rem"
}

const modalIshigakiStyles = {
  backgroundImage:`url(${IshigakiImage3})`,
  ...commonModalStyles
}

const modalMizunoStyles = {
  backgroundImage:`url(${MizunoImage3})`,
  ...commonModalStyles
}

const trimImage = {
  width:  "300px",
  height: "294px",
  borderRadius: "20%",
  backgroundPosition: "center",
  display: "inline-block",
  border: "1px solid white",
  cursor: "pointer"
}

const githubIconStyle = {
  width: "25px",
  height: "25px",
  color: "black",
}

const facebookIconStyle = {
  width: "25px",
  height: "25px",
  color: "#3b5998"
}

const devIconStyle = {
  width: "25px",
  height: "25px",
  color: "black"
}

const twitterIconStyle = {
  width: "25px",
  height: "25px",
  color: "#00aced",
  marginBottom: "-2px"
}

const qiitaIconStyle = {
  width: "25px",
  height: "25px",
  color: "#4cb10d"
}