import React from "react";
import Image from 'images/resources/service1.jpg';

const ServiceImage1 = () => {
  return (
    <img src={Image} alt="service img"></img>
  );
}

export default ServiceImage1;
