// @note 注意
//  GTagは public/index.html 側でも設定をしています。
//  変更をするときは上記ファイルも参照してください。
export const GTag = () => {
  const categories = () => {
    return (
      {
        top: "hp/index.html"
      }
    )
  }

  const sendEvent = (category: string, action: string, label: string) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined' ) {
      window.gtag("event", "click", category, action, label, 1)
    }
  }

  return { categories, sendEvent }
}