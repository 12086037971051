export const Mizuno = () => {
  const name = () => {
    return "水野 賢次";
  }

  const oneMessage = () => {
    return (
      <h4>
        ビジネスの成功の先にある、<br />
        みなさんの描いている夢を一緒に見させてください。
      </h4>
      );
  }

  const profiles = () => {
    return ({
      github: "https://github.com/mksava/",
      twitter: "https://twitter.com/mksava20/",
      qiita: "https://qiita.com/mksava/"
    })
  }

  const information = () => {
    return(
      <div className="text-left">
        <h4 className="text-md lg:text-2xl mt-2 mb-1">【経歴】</h4>
        <p className="text-sm lg:text-md">
          　2015年に地方ITベンチャー企業に就職後、RubyとRailsによるWeb系システム開発をメインに従事。<br />
          　その後IT業務就職支援としてプログラミングの講師活動や、モバイル系アプリのシステム改修、Webシステムのセキュリティ評価および脆弱性対応など、多種多様な業務を行なってきた。<br />
          　2018年からはシステム開発や運用保守の立ち上げ段階の責任者として、不確実性の多いフェイズでのマネジメント業務を中心に活動。<br />
          　初期段階におけるシステム開発のチーム基盤構築から、システム開発後のメンテナンスまでトータルで考えた運用で、ビジネスの拡大に貢献をしてきた。<br />
        </p>
        <h4 className="text-md lg:text-2xl mt-4 mb-1">【主な実績】</h4>
        <p className="text-sm lg:text-md">
          　社内評価管理システム構築、医療系サービスシステムの改修、ECサイトの構築・スクラッチ開発、チャットサービス連携システムの構築・スクラッチ開発、アドレス張管理システム改修, iOS/Androidアプリの開発保守、Webサイトのセキュリティ評価、プログラミング講師・就職支援、日本トップシェアシステムの運用保守設計<br />
        </p>
        <h4 className="text-md lg:text-2xl mt-4 mb-1">【システム開発について】</h4>
        <p className="text-sm lg:text-md">
          　多くのアイディアをすぐに試して形にできるのは、迅速で高品質なものを提供できる現場のエンジニアがいて初めて出来ることです。しかし、まだ私たちの業界では、現場のエンジニアが軽視されるところも少なくないと感じています。<br />
          　ビジネスやシステムの目的を誰よりも理解している者が、現場に入って直接システムの作り込みをする。これがスタートアップ・スモールスタートフェイズのビジネスを成功させるために必要なものであると信じています。そうした現場のエンジニアとして、ITシステム開発・運用のプロとして、みなさんのビジネスをサポートさせて頂きます。<br />
          　そしてビジネスの成功の先にある、みなさんの描いている夢を一緒に見させてください。よろしくお願いいたします。<br />
        </p>
      </div>
    )
  }

  return(
    { name, oneMessage, profiles, information }
  )
}