import React from "react";
import Image from 'images/resources/e_mail.png';

// スパム対策のためにメールアドレスを動的に設定
const e1 = "inf";
const e2 = "o@ze";
const e3 = "roichi-ha";
const e4 = "cker.com";

// info@zeroichi-hacker.com
const ead = `${e1}${e2}${e3}${e4}`;

// テキストをクリップボードにコピーする関数
const copyTextToClipboard = async (text: string): Promise<void> => {
  // Clipboard APIが使える環境なら使う
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    alert("メールアドレスをコピーしました。");
    return
  }

  // Clipboard APIが使えない環境なら、document.execCommandを使う
  // Clipboard APIは対応ブラウザがまだ少ないので、未対応の場合に備えて非推奨のexecCommandも実装しています
  // テキストエリアを一時的に生成して、そこにテキストを入れてコピーする
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);

  // iOSの場合は、textareaの選択範囲を設定する必要がある
  const iosTextSelect = () => {
    // 生成したテキストエリアをrangeに入れる
    const range = document.createRange();
    range.selectNodeContents(textarea);

    // 範囲選択している部分があれば、削除してからrangeを選択範囲に含める
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    // テキストの0~99999(最大)文字を選択状態にする
    textarea.setSelectionRange(0, 999999);
  }

  const isIos = navigator.userAgent.match(/ipad|iphone/i);

  if (isIos) {
    iosTextSelect();
  } else {
    // iOS以外はこの処理
    textarea.select();
  }

  // 選択されたテキストをコピーして、テキストエリアを削除する
  document.execCommand('copy');
  alert("メールアドレスをコピーしました。");
  document.body.removeChild(textarea);
};


// メールアドレス画像をクリックしたら、メールアドレスをコピーする
export const CopyEmailImage: React.FC = () => {
  return (
    <div>
      <button
        onClick={() => copyTextToClipboard(ead)}
        style={{ border: "none", backgroundColor: "transparent", padding: "0" }}
      >
        <img src={Image} style={{ width: "230px", verticalAlign: "middle" }} alt="e mail img button"></img>
      </button>
      <p　style={{ fontSize: "0.75rem", color: "GrayText" }}>
        ※クリックでメールアドレスをコピー
      </p>
    </div>
  );
}
