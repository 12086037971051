import React from "react";
import Image from 'images/resources/logo.png';

const Logo = () => {
  return (
    <div>
      <img src={Image} style={{ width: "4rem", height: "auto", marginRight: "0.5rem" }} alt="logo"></img>
    </div>
  );
}

export default Logo;
