import { styled } from "styled-components";


// 各セクション共通で使用しているスタイルコンポーネント //
export const Container = styled.div`
  width: 85%;
  max-width: 1280px;
  text-align: center;
  margin: 0 auto;
  @media ( max-width: 640px ) {
    width: 90%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`