export const Ishigaki = () => {
  const name = () => {
    return "石垣 尚紀";
  }

  const oneMessage = () => {
    return (
        <h4>
          構想段階から開発中のお悩みまで、<br />
          ご相談ください。
        </h4>
      );
  }

  const profiles = () => {
    return({
      github: "https://github.com/n350071/",
      twitter: "https://twitter.com/n350071/",
      facebook: "https://www.facebook.com/n350071/",
      devto: "https://dev.to/n350071/",
    })
  }

  const information = () => {
    return(
      <div className="text-left">
        <h4 className="text-md lg:text-2xl mt-2 mb-1">【経歴】</h4>
        <p className="text-sm lg:text-md">
          　2010年に日本ユニシス株式会社に新卒入社。SEとして一般的な開発経験を積む過程で、プロジェクトの炎上や、仕様通りだけど使いにくいシステムを経験する。<br />
          　2015年に小規模の開発会社に転職。炎上プロジェクトの建て直しに成功、顧客満足度は96%を達成。一方で、管理の限界を感じ、速さと品質の両立のためプログラマへの転身を決める。<br />
          　2017年に管理もできるRubyプログラマとして独立。構想から２ヶ月でのリリースや、メンテナンス不能に陥ったプログラムを再生するなど、複数のプロジェクトを経験。<br />
          　2020年からは、フリーランスチームを組み、構想段階からシステムを作り上げるゼロイチフェーズに従事している。<br />
        </p>
        <h4 className="text-md lg:text-2xl mt-4 mb-1">【主な実績】</h4>
        <p className="text-sm lg:text-md">
          　不動産契約管理システム、BtoBマッチングシステム、仮説検証支援システム、広告配信管理システム、自動車工場管理システム、モバイルアプリ開発、顧客管理システム、事業場台帳システム、高速道路工事管理システム..など。<br />
        </p>
        <h4 className="text-md lg:text-2xl mt-4 mb-1">【システム開発を検討されている方へ】</h4>
        <p className="text-sm lg:text-md">
          　なんでもそうですが、システム開発においても、必ずうまくいくパターンはありません。<br />
          　しかし、「そんなことをしたら失敗する」という失敗パターンは数多く存在します。<br />
          　一方で、昨今の技術者不足やビジネスモデルなどの要因で、失敗パターンを避ける仕組みを構築できている組織はまだまだ少ないように感じます。<br />
          　私たちに発注するしないは別にして、避けたほうが良いことはお伝えしたいので、構想段階から開発中のお悩みまで、ご相談いただければ幸いです。<br />
        </p>
      </div>
    )
  }

  return(
    { name, oneMessage, profiles, information }
  )
}