import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
}
export const Card: React.FC<Props> = ({ className, children }) => (
  <div
    className={className}
    style={{
      width: '100%',
      boxShadow: '0 10px 28px rgba(0,0,0,.08)',
      padding: '3rem',
      borderWidth: '0',
      borderRadius: '0.5rem',
      backgroundColor: 'white',
    }}
  >
    {children}
  </div>
);