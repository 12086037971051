import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from 'images/Logo';
import { GTag } from 'models/GTag';
import LogoName from 'images/LogoName';
import { styled } from 'styled-components';

const gTag = GTag()
const gaHeaderCategory = gTag.categories().top
const gaAction = "hader_link"

function sendHeaderGa(e: any) {
  const label = e.currentTarget.getAttribute('data-ga-label')
  gTag.sendEvent(gaHeaderCategory, gaAction, label)
}

export const Header: React.FC = () => (
  <StyledHeader>
    <HeaderContainer>
      <HeaderLogo>
        <Logo />
        <LogoName />
      </HeaderLogo>
      <HeaderLink>
        <AnchorLink href="#member" data-ga-label="member" onClick={sendHeaderGa}>
          開発メンバ
        </AnchorLink>
        <AnchorLink href="#services" data-ga-label="services" onClick={sendHeaderGa}>
          開発サービス
        </AnchorLink>
        <AnchorLink href="#method" data-ga-label="method" onClick={sendHeaderGa}>
          開発方法
        </AnchorLink>
        <AnchorLink href="#develop" data-ga-label="develop" onClick={sendHeaderGa}>
          開発技術
        </AnchorLink>
      </HeaderLink>
      <AnchorLink href="#inquery" data-ga-label="inquery" onClick={sendHeaderGa}>
        <HeaderButton>相談する</HeaderButton>
      </AnchorLink>
      <ConsultationLink>
        <AnchorLink href="#inquery" data-ga-label="inquery" onClick={sendHeaderGa}>
          相談する
        </AnchorLink>
      </ConsultationLink>
    </HeaderContainer>
  </StyledHeader>
);

// CSS styles //
const HeaderContainer   = styled.div``
const HeaderLogo        = styled.div``
const HeaderLink        = styled.div``
const HeaderButton      = styled.button``
const ConsultationLink  = styled.div``
const StyledHeader      = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: var(--header-zindex);
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),
              0 1px 2px 0 rgba(0,0,0,.06);
  /* PC画面サイズ（基本）のスタイル */
  ${HeaderContainer} {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0.5rem 2rem;
  }
  ${HeaderLogo} {
    /* widthの大きさで、ヘッダーリンクとの間隔を調節しています */
    width: 8rem;
    display: flex;
    align-items: center;
  }
  ${HeaderLink} {
    a {
      font-size: 1.125rem;
      color: var(--theme-color);
      padding: 0 1rem;
      text-decoration: inherit;
      &:hover {
        color: var(--theme-color-hover);
      }
    }
    /* サイズの関係でロゴとリンクが被ってしまうためヘッダーリンクだけこのブレイクポイントで非表示にしています */
    @media ( max-width: 992px ) {
      display: none; 
    }
  }
  ${HeaderButton} {
    font-size: 1.25rem;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 0.25rem;
    color: white;
    background-color: var(--theme-color);
    &:hover {
      background-color: var(--theme-color-hover);
    }
  }
  ${ConsultationLink} {
    display: none;
  }
  /* スマホ画面サイズのスタイル */
  @media ( max-width: 640px ) {
    ${HeaderContainer} {
      flex-direction: column;
      padding: 0.5rem 1rem;
      ${HeaderLogo} {
        justify-content: center;
        padding-top: 0.5rem;
      }
      ${HeaderLink} {
        display: none;
      }
      ${HeaderButton} {
        display: none;
      }
      ${ConsultationLink} {
        display: block;
        justify-content: center;
        a {
          font-size: 0.875rem;
          color: var(--theme-color);
          text-decoration: none;
        }
      }
    }
  }
`